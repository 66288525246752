import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalityQuizQuestionDTO, Tag, TagManager } from 'common';
import { TextAnswerModel } from '../observable-gesture-result/AnswerModel';

@Component({
    selector: 'hiji-question-ouverte',
    templateUrl: './question-ouverte.component.html',
    styleUrls: ['./question-ouverte.component.scss'],
    standalone: false
})
export class QuestionOuverteComponent {
  @Input()
  question: ModalityQuizQuestionDTO;
  @Input()
  hideSkill: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  questionNumber: number = null;
  @Input()
  totalQuestions: number = null;
  @Output()
  observe: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  answer: string = null;
  @Input()
  showAnswerDates: boolean = false;
  @Input()
  textAnswers: TextAnswerModel[];
  @Input()
  loadingAutoSave: boolean = false;
  @Input()
  autoSaved: boolean = false;

  showTagDesc: Tag = null;

  constructor(public tagManager: TagManager) {}

  trackByIndex(index: number, el: any): number {
    return index;
  }
}
