<ng-template #answer let-criteriaValue="criteriaValue" let-criteriaLabel="criteriaLabel">
  @if (getAnswersUsers(criteriaValue).length === 0 || evaluationMode) {
    <hiji-radio-answer
      [showMickeyEars]="showMickeyEars"
      [answers]="getAnswers(criteriaValue)"
      [calculateDonutByNumberOfAnswerTypes]="calculateDonutByNumberOfAnswerTypes"
    ></hiji-radio-answer>
  } @else {
    <hiji-radio-answer
      [showMickeyEars]="showMickeyEars"
      [answers]="getAnswers(criteriaValue)"
      [calculateDonutByNumberOfAnswerTypes]="calculateDonutByNumberOfAnswerTypes"
      [ngbTooltip]="acquisitionMode ? answerusersacquisition : answerusers"
    ></hiji-radio-answer>
  }
  <ng-template #answerusers>
    <ng-container>
      @for (user of getAnswersUsers(criteriaValue); track $index) {
        <div>{{ user }}</div>
      }
    </ng-container>
  </ng-template>

  <ng-template #answerusersacquisition>
    <ng-container>
      <div class="row">
        <div class="col-6">
          <b class="mb-1">{{ 'og.result.Avant' | translate }}</b>
          @for (user of getAnswersUsersAcquistionBefore(criteriaValue); track $index) {
            <div>{{ user }}</div>
          }
        </div>
        <div class="col-6">
          <b class="mb-1">{{ 'og.result.Après' | translate }}</b>
          @for (user of getAnswersUsersAcquistionAfter(criteriaValue); track $index) {
            <div>{{ user }}</div>
          }
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #tooltipDates>
    <div class="d-flex flex-column">
      <div class="text-center" [style]="'color: ' + dateInfo.color" *ngFor="let dateInfo of getAnswerDates(criteriaValue); let last = last">
        {{ dateInfo.user !== undefined ? dateInfo.user : '' }}
        {{ dateInfo.date | localizedDate }}
      </div>
    </div>
  </ng-template>
  <span class="text-center mt-2" [ngClass]="criteriaTextClasses">
    {{ criteriaLabel | ms }}
  </span>
  <ng-container *ngIf="showAnswerDates && getAnswerDates(criteriaValue).length > 0">
    <i class="fa-light fa-calendar" [ngbTooltip]="tooltipDates" container="body"></i>
  </ng-container>
</ng-template>

<div
  class="container-fluid p-0 my-fs-2 my-fs-sm-3"
  [class.bg-secondary]="observableGesture != null && observableGesture.highlight !== null && observableGesture.highlight !== undefined"
  [class.text-white]="observableGesture != null && observableGesture.highlight !== null && observableGesture.highlight !== undefined"
>
  <ng-content select="[top]"></ng-content>
  <ng-container *ngIf="showSkill && observableGesture != null">
    <ng-container *ngFor="let tag of getAssignedTags()">
      <div class="row justify-content-center" *ngIf="!tagManager.getTag(tag.idTag).hideInFO">
        <div class="col-auto d-flex align-items-baseline my-fs-2">
          <i class="fa-light fa-tools me-2"></i>
          <hiji-tag-display class="d-block ellipsis" [idTag]="tag.idTag" [frontMode]="true" (descInfoClick)="toggleDesc($event)"></hiji-tag-display>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="row mt-1" *ngIf="showTagDesc !== null">
    <div class="col">
      <div class="alert alert-primary" style="white-space: pre-line">{{ showTagDesc.description | ms }}</div>
    </div>
  </div>
  <div class="row mt-2" *ngIf="observableGesture !== null">
    <div class="col" [class.fw-bold]="ogBold">{{ observableGesture.observableGestureName | ms }}</div>
  </div>
  <div
    class="row mt-1 px-3"
    *ngIf="
      observableGesture !== null && observableGesture.observableGestureDescription !== null && (observableGesture.observableGestureDescription | ms) !== ''
    "
  >
    <div class="col text-muted my-fs-2" style="white-space: pre-line">{{ observableGesture.observableGestureDescription | ms }}</div>
  </div>
  <div
    class="row g-0 justify-content-around mt-2"
    [class.scale5]="criteria.type === CriteriaType.ECHELLE_5"
    *ngIf="criteria.type === CriteriaType.ECHELLE_4 || criteria.type === CriteriaType.ECHELLE_5"
  >
    <div
      class="p-2 align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
      [class.pointer]="evaluationMode"
      (click)="emit(criteria.value1)"
    >
      <ng-container
        *ngTemplateOutlet="
          answer;
          context: {
            criteriaValue: criteria.value1,
            criteriaLabel: criteria.label1,
          }
        "
      ></ng-container>
    </div>
    <div
      class="p-2 align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
      [class.pointer]="evaluationMode"
      (click)="emit(criteria.value2)"
    >
      <ng-container
        *ngTemplateOutlet="
          answer;
          context: {
            criteriaValue: criteria.value2,
            criteriaLabel: criteria.label2,
          }
        "
      ></ng-container>
    </div>
    <div
      class="p-2 align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
      [class.pointer]="evaluationMode"
      (click)="emit(criteria.value3)"
    >
      <ng-container
        *ngTemplateOutlet="
          answer;
          context: {
            criteriaValue: criteria.value3,
            criteriaLabel: criteria.label3,
          }
        "
      ></ng-container>
    </div>
    <div
      class="p-2 align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
      [class.pointer]="evaluationMode"
      (click)="emit(criteria.value4)"
    >
      <ng-container
        *ngTemplateOutlet="
          answer;
          context: {
            criteriaValue: criteria.value4,
            criteriaLabel: criteria.label4,
          }
        "
      ></ng-container>
    </div>
    <div
      class="p-2 align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
      [class.pointer]="evaluationMode"
      (click)="emit(criteria.value5)"
      *ngIf="criteria.type === CriteriaType.ECHELLE_5"
    >
      <ng-container
        *ngTemplateOutlet="
          answer;
          context: {
            criteriaValue: criteria.value5,
            criteriaLabel: criteria.label5,
          }
        "
      ></ng-container>
    </div>
  </div>
  <div class="row g-0 mt-2" *ngIf="criteria.type === CriteriaType.BINAIRE">
    <div class="col-6 p-2 align-items-center d-flex flex-column" [class.pointer]="evaluationMode" (click)="emit(criteria.value1)">
      <ng-container
        *ngTemplateOutlet="
          answer;
          context: {
            criteriaValue: criteria.value1,
            criteriaLabel: criteria.label1,
          }
        "
      ></ng-container>
    </div>
    <div class="col-6 p-2 align-items-center d-flex flex-column" [class.pointer]="evaluationMode" (click)="emit(criteria.value5)">
      <ng-container
        *ngTemplateOutlet="
          answer;
          context: {
            criteriaValue: criteria.value5,
            criteriaLabel: criteria.label5,
          }
        "
      ></ng-container>
    </div>
  </div>

  <ng-container *ngIf="criteria.type === CriteriaType.QUESTION_OUVERTE">
    <ng-container *ngFor="let textAnswer of textAnswers; let index = index; trackBy: trackByIndex">
      <div class="row mt-1">
        <div class="col" [ngStyle]="{ color: textAnswer.color }" *ngIf="textAnswer.name !== null">
          {{ textAnswer.name }}
          <span *ngIf="textAnswer.date !== undefined && showAnswerDates">({{ textAnswer.date | localizedDate }})</span>
        </div>
      </div>
      <div class="row px-3 g-0 mt-2">
        <div class="col">
          <textarea class="form-control" name="test-{{ index }}" rows="3" disabled [ngModel]="textAnswer.text"></textarea>
        </div>
      </div>
    </ng-container>

    <div class="row px-3 g-0 mt-2" *ngIf="evaluationMode">
      <div class="col">
        <textarea
          class="form-control"
          name="{{ observableGesture.idObservableGesture }}"
          rows="3"
          [(ngModel)]="text"
          (ngModelChange)="observeText.emit(text)"
        ></textarea>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="criteria.type === CriteriaType.NPS || criteria.type === CriteriaType.ECHELLE_10">
    <div class="row px-3 mt-2">
      <div class="col text-muted">
        {{ criteria.label1 | ms }}
      </div>
    </div>
    <div class="row justify-content-center flex-nowrap px-3 gx-2 gx-md-3 nps mt-2">
      <div class="col-auto d-flex align-items-center" [class.pointer]="evaluationMode" (click)="emit(val)" *ngFor="let val of npsVals.slice(0, 6)">
        @if (getAnswersUsers(val).length === 0 || evaluationMode) {
          <hiji-radio-answer
            [showMickeyEars]="showMickeyEars"
            [answers]="getAnswers(val)"
            [calculateDonutByNumberOfAnswerTypes]="calculateDonutByNumberOfAnswerTypes"
            text="{{ val / 10 }}"
          ></hiji-radio-answer>
        } @else {
          <hiji-radio-answer
            [showMickeyEars]="showMickeyEars"
            [answers]="getAnswers(val)"
            [calculateDonutByNumberOfAnswerTypes]="calculateDonutByNumberOfAnswerTypes"
            [ngbTooltip]="acquisitionMode ? answerusersacquisition : answerusers"
            text="{{ val / 10 }}"
          ></hiji-radio-answer>
        }
        <ng-template #answerusers>
          <ng-container>
            @for (user of getAnswersUsers(val); track $index) {
              <div>{{ user }}</div>
            }
          </ng-container>
        </ng-template>
        <ng-template #answerusersacquisition>
          <ng-container>
            <div class="row">
              <div class="col-6">
                <b class="mb-1">{{ 'og.result.Avant' | translate }}</b>
                @for (user of getAnswersUsersAcquistionBefore(val); track $index) {
                  <div>{{ user }}</div>
                }
              </div>
              <div class="col-6">
                <b class="mb-1">{{ 'og.result.Après' | translate }}</b>
                @for (user of getAnswersUsersAcquistionAfter(val); track $index) {
                  <div>{{ user }}</div>
                }
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <div class="row justify-content-center flex-nowrap px-3 gx-2 gx-md-3 nps">
      <div class="col-auto d-flex align-items-center" [class.pointer]="evaluationMode" (click)="emit(val)" *ngFor="let val of npsVals.slice(6)">
        @if (getAnswersUsers(val).length === 0 || evaluationMode) {
          <hiji-radio-answer
            [showMickeyEars]="showMickeyEars"
            [answers]="getAnswers(val)"
            [calculateDonutByNumberOfAnswerTypes]="calculateDonutByNumberOfAnswerTypes"
            text="{{ val / 10 }}"
          ></hiji-radio-answer>
        } @else {
          <hiji-radio-answer
            [showMickeyEars]="showMickeyEars"
            [answers]="getAnswers(val)"
            [calculateDonutByNumberOfAnswerTypes]="calculateDonutByNumberOfAnswerTypes"
            [ngbTooltip]="acquisitionMode ? answerusersacquisition : answerusers"
            text="{{ val / 10 }}"
          ></hiji-radio-answer>
        }
        <ng-template #answerusers>
          <ng-container>
            @for (user of getAnswersUsers(val); track $index) {
              <div>{{ user }}</div>
            }
          </ng-container>
        </ng-template>
        <ng-template #answerusersacquisition>
          <ng-container>
            <div class="row">
              <div class="col-6">
                <b class="mb-1">{{ 'og.result.Avant' | translate }}</b>
                @for (user of getAnswersUsersAcquistionBefore(val); track $index) {
                  <div>{{ user }}</div>
                }
              </div>
              <div class="col-6">
                <b class="mb-1">{{ 'og.result.Après' | translate }}</b>
                @for (user of getAnswersUsersAcquistionAfter(val); track $index) {
                  <div>{{ user }}</div>
                }
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
    <div class="row px-3">
      <div class="col text-muted text-end">
        {{ criteria.label2 | ms }}
      </div>
    </div>
  </ng-container>

  <ng-content select="[bottom]"></ng-content>
</div>
