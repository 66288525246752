import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ic2Module, MsPipe } from '@ic2/ic2-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { HijiCommonModule, TRANSLATE_CHILD_CONFIG } from 'common';
import { ObservableGestureEvaluationComponent } from './observable-gesture-evaluation/observable-gesture-evaluation.component';
import { ObservableGestureResultComponent } from './observable-gesture-result/observable-gesture-result.component';
import { QuestionOuverteComponent } from './question-ouverte/question-ouverte.component';
import { QuestionQcmComponent } from './question-qcm/question-qcm.component';
import { RadioAnswerComponent } from './radio-answer/radio-answer.component';

@NgModule({
  declarations: [ObservableGestureEvaluationComponent, ObservableGestureResultComponent, QuestionQcmComponent, QuestionOuverteComponent, RadioAnswerComponent],
  imports: [CommonModule, Ic2Module, FormsModule, ReactiveFormsModule, NgbModule, HijiCommonModule, TranslateModule.forChild(TRANSLATE_CHILD_CONFIG)],
  exports: [ObservableGestureEvaluationComponent, ObservableGestureResultComponent, QuestionQcmComponent, QuestionOuverteComponent],
  providers: [MsPipe],
})
export class ObservableGestureModule {}
