import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export interface Anwser {
  color: string;
  nb: number;
  name: string;
}
interface ComputedAnwser {
  color: string;
  nb: number;
  name: string;
  start: number;
  part: number;
}
@Component({
    selector: 'hiji-radio-answer',
    templateUrl: './radio-answer.component.html',
    styleUrls: ['./radio-answer.component.scss'],
    standalone: false
})
export class RadioAnswerComponent implements OnInit, OnChanges {
  @Input()
  showMickeyEars: boolean = true;
  @Input()
  calculateDonutByNumberOfAnswerTypes: boolean = false;
  @Input()
  answers: Anwser[];
  @Input()
  text: string = null;
  computedAnswers: ComputedAnwser[];
  hasAnswers: boolean;

  ngOnInit(): void {
    this.compute();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.compute();
  }

  compute() {
    this.computedAnswers = this.computeAnswers();
    this.hasAnswers = this.computedAnswers.map((a) => a.nb).reduce((a, b) => a + b, 0) > 0;
  }

  computeAnswers() {
    let i = 0;
    const nonEmptyAnswers = this.answers.filter((a) => a.nb > 0);
    return nonEmptyAnswers
      .sort((a, b) => a.nb - b.nb)
      .map((a) => {
        let part = 0;
        if (this.calculateDonutByNumberOfAnswerTypes) {
          const length = nonEmptyAnswers.length;
          part = length === 0 ? 0 : 360 / nonEmptyAnswers.length;
        } else {
          const total = this.answers.map((n) => n.nb).reduce((n, b) => n + b, 0);
          part = (this.answers.find((b) => b.color === a.color).nb / total) * 360;
        }
        i += part;
        return {
          name: a.name,
          color: a.color,
          nb: a.nb,
          start: i - part,
          part: part,
        };
      });
  }
}
