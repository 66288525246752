<div
  class="rounded-3 p-2 my-fs-2 my-fs-sm-3 position-relative pb-4"
  [class.border-danger]="borderDanger"
  [class.border]="withBorder"
  [class.bg-white]="observableGesture.highlight === null || observableGesture.highlight === undefined"
  [class.bg-secondary]="observableGesture.highlight !== null && observableGesture.highlight !== undefined"
  [class.text-white]="observableGesture.highlight !== null && observableGesture.highlight !== undefined"
  [ngStyle]="{ '--customized-radio-bg-color': 'var(' + color() + ')' }"
>
  @if (loadingAutoSave || autoSaved) {
    <div class="position-absolute bottom-0 end-0 pe-2 pb-1">
      @if (loadingAutoSave) {
        <i class="fa-light fa-spinner spinning"></i>
      } @else {
        <i class="fa-light fa-floppy-disk text-success" ngbTooltip="{{ 'question.qcm.Votre réponse a été enregistrée automatiquement' | translate }}"></i>
      }
    </div>
  }

  <ng-container *ngIf="!hideSkill">
    <ng-container *ngFor="let idTag of getAssignedTags()">
      <div class="row justify-content-center" *ngIf="!tagManager.getTag(idTag).hideInFO">
        <div class="col-auto d-flex align-items-baseline my-fs-2">
          <i class="fa-light fa-tools me-2"></i>
          <hiji-tag-display class="d-block ellipsis" [idTag]="idTag" [frontMode]="true" (descInfoClick)="toggleDesc($event)"></hiji-tag-display>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="row mt-1" *ngIf="showTagDesc !== null">
    <div class="col">
      <div class="alert alert-primary" style="white-space: pre-line">{{ showTagDesc.description | ms }}</div>
    </div>
  </div>
  <div class="row mt-2 px-3">
    <div class="col-auto" *ngIf="questionNumber !== null">{{ questionNumber }} / {{ totalQuestions }}</div>
    <div class="col">
      {{ observableGesture.observableGestureName | ms }}
      <i
        class="fa-light fa-info-circle ms-2 pointer"
        (click)="showOGDesc = !showOGDesc"
        *ngIf="showDescOGInfoButton && observableGesture.observableGestureDescription !== null && (observableGesture.observableGestureDescription | ms) !== ''"
      ></i>
    </div>
  </div>

  <div
    class="row mt-1 px-3"
    *ngIf="
      observableGesture.observableGestureDescription !== null &&
      (observableGesture.observableGestureDescription | ms) !== '' &&
      (!showDescOGInfoButton || showOGDesc)
    "
  >
    <div class="col text-muted my-fs-2" style="white-space: pre-line">{{ observableGesture.observableGestureDescription | ms }}</div>
  </div>
  <div
    class="row g-0 justify-content-around mt-2"
    [class.scale5]="criteria.type === CriteriaType.ECHELLE_5"
    *ngIf="criteria.type === CriteriaType.ECHELLE_4 || criteria.type === CriteriaType.ECHELLE_5"
  >
    <div
      class="align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
    >
      <input
        class="answer-radio"
        [class.pointer]="!disabled"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        type="radio"
        (click)="observable($event, criteria.value1)"
        [value]="criteria.value1"
        [checked]="observed && value === criteria.value1"
      />
      <span class="text-center mt-1">{{ criteria.label1 | ms }}</span>
    </div>
    <div
      class="align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
    >
      <input
        class="answer-radio"
        [class.pointer]="!disabled"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        type="radio"
        (click)="observable($event, criteria.value2)"
        [value]="criteria.value2"
        [checked]="observed && value === criteria.value2"
      />
      <span class="text-center mt-1">{{ criteria.label2 | ms }}</span>
    </div>
    <div
      class="align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
    >
      <input
        class="answer-radio"
        [class.pointer]="!disabled"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        type="radio"
        (click)="observable($event, criteria.value3)"
        [value]="criteria.value3"
        [checked]="observed && value === criteria.value3"
      />
      <span class="text-center mt-1">{{ criteria.label3 | ms }}</span>
    </div>
    <div
      class="align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
    >
      <input
        class="answer-radio"
        [class.pointer]="!disabled"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        type="radio"
        (click)="observable($event, criteria.value4)"
        [value]="criteria.value4"
        [checked]="observed && value === criteria.value4"
      />
      <span class="text-center mt-1">{{ criteria.label4 | ms }}</span>
    </div>
    <div
      class="align-items-center d-flex flex-column"
      [class.col-3]="criteria.type === CriteriaType.ECHELLE_4"
      [class.col-2]="criteria.type === CriteriaType.ECHELLE_5"
      *ngIf="criteria.type === CriteriaType.ECHELLE_5"
    >
      <input
        class="answer-radio"
        [class.pointer]="!disabled"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        type="radio"
        (click)="observable($event, criteria.value5)"
        [value]="criteria.value5"
        [checked]="observed && value === criteria.value5"
      />
      <span class="text-center mt-1">{{ criteria.label5 | ms }}</span>
    </div>
  </div>

  <div class="row g-0 mt-2" *ngIf="criteria.type === CriteriaType.BINAIRE">
    <div class="col-6 align-items-center d-flex flex-column">
      <input
        class="answer-radio"
        [class.pointer]="!disabled"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        type="radio"
        (click)="observable($event, criteria.value1)"
        [value]="criteria.value1"
        [checked]="observed && value === criteria.value1"
      />
      <span class="text-center mt-1">{{ criteria.label1 | ms }}</span>
    </div>
    <div class="col-6 align-items-center d-flex flex-column">
      <input
        class="answer-radio"
        [class.pointer]="!disabled"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        type="radio"
        (click)="observable($event, criteria.value5)"
        [value]="criteria.value5"
        [checked]="observed && value === criteria.value5"
      />
      <span class="text-center mt-1">{{ criteria.label5 | ms }}</span>
    </div>
  </div>

  <div class="row px-3 g-0 mt-2" *ngIf="criteria.type === CriteriaType.QUESTION_OUVERTE">
    <div class="col">
      <textarea
        class="form-control"
        name="{{ name === null ? observableGesture.idObservableGesture : name }}"
        rows="3"
        [(ngModel)]="text"
        (ngModelChange)="textChange()"
        [disabled]="disabled"
      ></textarea>
    </div>
  </div>

  <ng-container *ngIf="criteria.type === CriteriaType.NPS || criteria.type === CriteriaType.ECHELLE_10">
    <div class="row px-3 mt-2">
      <div class="col text-muted">
        {{ criteria.label1 | ms }}
      </div>
    </div>
    <div class="row justify-content-center flex-nowrap px-3 gx-2 gx-md-3 nps">
      <div class="col-auto d-flex align-items-center position-relative" *ngFor="let val of npsVals.slice(0, 6)">
        <input
          class="answer-radio"
          [class.pointer]="!disabled"
          name="{{ name === null ? observableGesture.idObservableGesture : name }}"
          type="radio"
          (click)="observable($event, val)"
          [value]="val"
          [checked]="observed && value === val"
        />
        <div class="position-absolute start-50 top-50 translate-middle nps-text" [class.pointer]="!disabled" (click)="observable($event, val)">
          {{ val / 10 }}
        </div>
      </div>
    </div>
    <div class="row justify-content-center flex-nowrap px-3 gx-2 gx-md-3 nps mt-2">
      <div class="col-auto d-flex align-items-center position-relative" *ngFor="let val of npsVals.slice(6)">
        <input
          class="answer-radio"
          [class.pointer]="!disabled"
          name="{{ name === null ? observableGesture.idObservableGesture : name }}"
          type="radio"
          (click)="observable($event, val)"
          [value]="val"
          [checked]="observed && value === val"
        />
        <div class="position-absolute start-50 top-50 translate-middle nps-text" [class.pointer]="!disabled" (click)="observable($event, val)">
          {{ val / 10 }}
        </div>
      </div>
    </div>
    <div class="row px-3">
      <div class="col text-muted text-end">
        {{ criteria.label2 | ms }}
      </div>
    </div>
  </ng-container>

  <div class="row px-3 justify-content-end" *ngIf="(criteria.description | ms) !== '' && first">
    <div class="col text-muted my-fs-2" style="white-space: pre-line" *ngIf="showCriteriaDesc">{{ criteria.description | ms }}</div>
    <div class="col-auto text-info text-decoration-underline pointer" (click)="showCriteriaDesc = !showCriteriaDesc">
      {{ 'observable-gesture-evaluation.Légende' | translate }}
    </div>
  </div>

  <ng-content select="[bottom]"></ng-content>
</div>
