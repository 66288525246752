<div class="position-relative w-100 h-100">
  <div class="answer-radio w-100 h-100" *ngIf="!hasAnswers"></div>

  <div class="parent-pie m-auto w-100 h-100" *ngIf="hasAnswers">
    @for (answer of computedAnswers; track $index) {
      @if (answer.nb > 0) {
        <div
          class="pie"
          [class.big]="answer.part >= 180"
          [style]="'--bg: ' + answer.color + ';--start: ' + answer.start + 'deg;--part:' + answer.part + 'deg'"
          [title]="answer.name"
        ></div>
      }
      @if (answer.nb > 0 && showMickeyEars) {
        <div
          class="oreille badge rounded-circle"
          [style]="'--angle:' + (-90 + answer.start + answer.part / 2) + 'deg;--bg: ' + answer.color"
          [title]="answer.name"
        >
          <div>{{ answer.nb }}</div>
        </div>
      }
    }
  </div>
  @if (text !== null) {
    <div class="position-absolute start-50 top-50 translate-middle nps-text">{{ text }}</div>
  }
</div>
